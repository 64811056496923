export default {
    namespaced: true,
    state: {
        isShowLogo: true,
        homeHeight: 0,
        cosmosHeight: 0,
        playerHeight: 0,
        sparkleEssenceHeight: 0,
        missionHeight: 0,
        communityHeight: 0,
        winScrollTop: 0,
        showLoginBtn: true,
        thirdPartyAuthType: '',
        notificationInfo: null,
        waitNotification: null,
    },
    getters: {
        isShowLogo(state) {
            return state.isShowLogo
        },
        homeHeight(state) {
            return state.homeHeight
        },
        cosmosHeight(state) {
            return state.cosmosHeight
        },
        playerHeight(state) {
            return state.playerHeight
        },
        sparkleEssenceHeight(state) {
            return state.sparkleEssenceHeight
        },
        missionHeight(state) {
            return state.missionHeight
        },
        communityHeight(state) {
            return state.communityHeight
        },
        winScrollTop(state) {
            return state.winScrollTop
        },
        showLoginBtn(state) {
            return state.showLoginBtn
        },
        thirdPartyAuthType(state) {
            return state.thirdPartyAuthType
        },
        notificationInfo(state) {
            return state.notificationInfo
        },
        waitNotification(state) {
            return state.waitNotification
        }
    },
    mutations: {
        updateLogo(state, payload) {
            state.isShowLogo = payload
        },
        getHomeHeight(state, val) {
            state.homeHeight = val
        },
        getCosmosHeight(state, val) {
            state.cosmosHeight = val
        },
        getPlayerHeight(state, val) {
            state.playerHeight = val
        },
        getSparkleEssenceHeight(state, val) {
            state.sparkleEssenceHeight = val
        },
        getMissionHeight(state, val) {
            state.missionHeight = val
        },
        getCommunityHeight(state, val) {
            state.communityHeight = val
        },
        getScrollTop(state, val) {
            state.winScrollTop = val
        },
        updateLoginBtn(state, val) {
            state.showLoginBtn = val
        },
        setThirdPartyAuthType(state, val) {
            state.thirdPartyAuthType = val
        },
        clearGlobal(state) {
            state.thirdPartyAuthType = ''
            state.notificationInfo = null
        },
        updateNotification(state, val) {
            state.notificationInfo = val
        },
        updateWaitNotification(state, val) {
            state.waitNotification = val
        },
        clearWaitNotification(state, val) {
            state.waitNotification = null
        },
    },
}