import * as buffer from "buffer"
import process from "process"

if (typeof window.global === "undefined") {
    window.global = window
}

if (typeof (window).Buffer === "undefined") {
    window.Buffer = buffer.Buffer
}

if (typeof (window).process === "undefined") {
    window.process = process
}