<template>
    <div class="main-container">
        <router-view v-slot="{ Component }" :key="route.path">
            <keep-alive>
                <component :key="$route.name" :is="Component" v-if="$route.meta.keepAlive" />
            </keep-alive>
            <component :key="$route.name" :is="Component" v-if="!$route.meta.keepAlive" />
        </router-view>

        <Notification :notificationInfo="notificationInfo" v-if="notificationInfo?.isOpen" />

        <WaitNotification :waitNotification="waitNotification" v-if="waitNotification?.isOpen" />

    </div>
</template>

<script setup>
import { ref, computed } from "vue"
import { useStore, mapGetters } from 'vuex'
import { useRoute } from "vue-router"

const store = useStore()
const route = useRoute()

const [notificationInfo, waitNotification] = Object.values(mapGetters("global", ["notificationInfo", "waitNotification"])).map(it => computed(it.bind({ $store: store })))

</script>

<style lang='less' scoped>
.main-container {
    min-width: 350px;
    min-height: 100vh;
    background: #F5F5F5;
}
</style>