const imgPreloader = url => {
    return new Promise((resolve, reject) => {
        let image = new Image()

        image.onload = () => {
            resolve()
        }

        image.onerror = (err) => {
            console.log(url)
            reject(err)
        }

        image.src = url
    })
}

export const imgsPreloader = imgs => {
    let promiseArr = []

    imgs.forEach(element => {
        promiseArr.push(imgPreloader(element))
        // console.log(`预加载图片 ${element}`)
    })

    return Promise.all(promiseArr)
}