<template>
    <div class="notification radius-16 animate__animated animate__bounceInRight">
        <div class="flex col-center">
            <i class="icon" :class="[iconType]"></i>
            <div class="tip flex-1">
                {{ notificationInfo.msg }}
                <p class="trans-tip color-purple cur-pointer flex col-center" v-if="notificationInfo.isTransaction"
                    @click="toExplorer">
                    View on Explorer
                    <i class="path-icon"></i>
                </p>
            </div>
            <i class="dot radius-50"></i>
        </div>
    </div>
</template>
 
<script setup>
import { onMounted, computed } from "vue"
import { useStore } from 'vuex'

const props = defineProps({
    notificationInfo: {
        type: Object,
        default: null
    },
    type: {
        type: String,
        default: ''
    },
    msg: {
        type: Boolean,
        default: ''
    }
})

const store = useStore()

const iconType = computed(() => {
    return props.notificationInfo.type
})

onMounted(() => {
    setTimeout(() => {
        store.commit('global/updateNotification', { isOpen: false })
    }, 3000)
})

const toExplorer = () => {
    const url = props.notificationInfo.explorerUrl

    window.open(url, '_blank')
}

</script>
 
<style lang='less' scoped>
.notification {
    position: fixed;
    right: 24px;
    top: 100px;
    width: 335px;
    padding: 8px 12px;
    box-shadow: 0px 2px 0px 0px #000;
    border: 1px solid #000;
    background: #F5F4FF;
    z-index: 9999;

    .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 10px;

        &.warn {
            background-image: url('@/assets/image/common/warn_icon.svg');
        }

        &.success {
            background-image: url('@/assets/image/common/success_icon.svg');
        }

        &.error {
            background-image: url('@/assets/image/common/failed_icon.svg');
        }
    }

    .tip {
        font-size: 16px;
        color: #000;
        line-height: 1.5;

        .trans-tip {
            font-size: 15px;
            
            .path-icon {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url('@/assets/image/wallet/path_icon.png') no-repeat;
                background-size: 100% 100%;
                margin-left: 3px;
            }
        }
    }

    .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #FFDD5B;
        border: 1px solid #000000;
        position: absolute;
        right: 10px;
        top: 8px;
    }
}

@media screen and (max-width: 521px) {
    .notification {
        right: auto;
        left: 50%;
        top: 20px;
        margin-left: -167px;
    }
}
</style>