export default {
    namespaced: true,
    state: {
        isConnected: false,
        addr: '',
        name: '',
        caWallet: '',
        idToken: ''
    },
    getters: {
        address(state) {
            return state.addr
        },
        isConnected(state) {
            return state.isConnected
        },
        authType(state) {
            return state.name
        },
        caWallet(state) {
            return state.caWallet
        },
        idToken(state) {
            return state.idToken
        }
    },
    mutations: {
        connectWallet(state, payload) {
            state.addr = payload.address
            state.name = payload.name
            console.log(payload)
        },
        updateWalletConnected(state, payload) {
            state.isConnected = payload
        },
        setCa(state, payload) {
            state.caWallet = payload
        },
        setIdToken(state, payload) {
            state.idToken = payload
        },
        clearWallet(state) {
            state.isConnected = false
            state.addr = ''
            state.name = ''
            state.caWallet = ''
            state.idToken = ''
        }
    },
    actions: {

    },
}