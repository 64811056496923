import { createRouter, createWebHistory } from "vue-router"
import Main from '@/views/Main.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Main",
      component: Main,
      // redirect: "home",
      children: [
        {
          meta: {
            keepAlive: false,
          },
          path: "/",
          name: "home",
          component: () => import("@/views/login/Index.vue"),
        },
        {
          meta: {
            keepAlive: false,
          },
          path: "wallet",
          name: "wallet",
          component: () => import("@/views/wallet/Index.vue"),
        },
      ],
    },
    {
      path: "/:catchAll(.*)", // 此处需特别注意至于最底部
      redirect: "/",
    },
  ],
  scrollBehavior () {
    return { top: 0 }
  }
})

export default router