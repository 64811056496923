import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"
import PKG from '../../package.json'
import _cloneDeep from 'lodash/cloneDeep'

const versionKey = `${PKG.name}_version`
const localVersion = localStorage.getItem(versionKey)

const initModule = () => {
    // 获取modules文件夹内所有的模块
    const requireModules = import.meta.globEager("./modules/*.js")
    const modules = {}
    for (let i in requireModules) {
        // 将模块路径切割出模块名
        const moduleName = i.split("/")[i.split("/").length - 1].replace(".js", "")
        // 将模块放入modules
        modules[moduleName] = requireModules[i].default
    }
    return modules
}

const persistedStateOptions = {
    key: `${PKG.name}_main`,
    storage: window.localStorage,
    setState(key, state, storage) {
        const _state = _cloneDeep(state)

        if (localVersion !== PKG.version) {
            localStorage.removeItem(PKG.name)
            localStorage.setItem(versionKey, PKG.version)
        }

        return storage.setItem(key, JSON.stringify(_state))
    }
}

const store = createStore({
    state() {

    },
    mutations: {

    },
    modules: { ...initModule() },
    plugins: [
        createPersistedState(persistedStateOptions),
    ],
})

export default store