export default {
  namespaced: true,
  state: {
    token: "",
    userInfo: null,
    uniPassInfo: null,
    idToken: "",
    isDiscordAuth: false,
    isTwitterAuth: false,
    isTiktokAuth: false,
    defaultNftList: [], // 初始nft列表
    wearNftList: [], // 用户已穿戴的nft列表
    getUniPassAgain: false,
    invisibleSeqList: [],
    invisibleImageUrl: "",
  },
  getters: {
    token(state) {
      return state.token;
    },
    userInfo(state) {
      return state.userInfo;
    },
    uniPassInfo(state) {
      return state.uniPassInfo;
    },
    idToken(state) {
      return state.idToken;
    },
    isDiscordAuth(state) {
      return state.isDiscordAuth;
    },
    isTwitterAuth(state) {
      return state.isTwitterAuth;
    },
    isTiktokAuth(state) {
      return state.isTiktokAuth;
    },
    defaultNftList(state) {
      return state.defaultNftList;
    },
    wearNftList(state) {
      const list = state.wearNftList
      let arr = []

      list?.forEach(item => {
        item.url && arr.push(item.url)
      })

      return arr
    },
    getUniPassAgain(state) {
      return state.getUniPassAgain;
    },
    invisibleSeqList(state) {
      return state.invisibleSeqList;
    },
    invisibleImageUrl(state) {
      return state.invisibleImageUrl;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    logout(state) {
      state.token = "";
      state.userInfo = null;
      state.idToken = "";
      state.uniPassInfo = null;
      state.isDiscordAuth = false;
      state.isTwitterAuth = false;
      state.isTiktokAuth = false;
      state.getUniPassAgain = false;
      state.defaultNftList = [];
      state.wearNftList = [];
    },
    setUniPassInfo(state, data) {
      state.uniPassInfo = data;
    },
    setIdToken(state, token) {
      state.idToken = token;
    },
    updateDiscordAuth(state, val) {
      state.isDiscordAuth = val;
    },
    updateTwitterAuth(state, val) {
      state.isTwitterAuth = val;
    },
    updateTiktokAuth(state, val) {
      state.isTiktokAuth = val;
    },
    getDefaultNftList(state, data) {
      state.defaultNftList = data;
    },
    getWearNftList(state, data) {
      state.wearNftList = data;
    },
    updateGetUniPassAgain(state, data) {
      state.getUniPassAgain = data;
    },
    setInvisibleSeqList(state, data) {
      state.invisibleSeqList = data;
    },
    setInvisibleImageUrl(state, data) {
      state.invisibleImageUrl = data;
    },
  },
  actions: {},
};
