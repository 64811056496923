export default {
    namespaced: true,
    state: {
        cacheNftList: [],
    },
    getters: {
        cacheNftList(state) {
            return state.cacheNftList
        }
    },
    mutations: {
        updateCacheNftList(state, payload) {
            state.cacheNftList.push(payload)
        },
        resetCacheNftList(state, list) {
            state.cacheNftList = list
        },
        clearCacheNftList(state) {
            state.cacheNftList = []
        }
    },
    actions: {

    },
}