import Clipboard from "clipboard"
import store from "@/store"

export default function (app) {
    app.directive('copy', {
        mounted(el, binding) {
            const obj = new Clipboard(el)

            obj.on("success", () => {
                const data = {
                    isOpen: true,
                    msg: 'Copied Successfully',
                    type: 'success'
                }

                store.commit('global/updateNotification', data)

                if (binding.value && binding.value.call) {
                    setTimeout(() => {
                        binding.value.call()
                    }, 500)
                }
            })
            obj.on("error", () => {
                const data = {
                    isOpen: true,
                    msg: 'Copy failed, please try again',
                    type: 'error'
                }

                store.commit('global/updateNotification', data)
            })
        },
    })
}
