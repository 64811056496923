<template>
    <div class="notification radius-16 animate__animated animate__bounceInRight">
        <div class="flex col-center">
            <img class="icon" :src="iconImg" alt="">
            <div class="tip flex-1">
                Waiting for wallet
                <p class="sub-title" v-if="!waitNotification.isTransaction">Go to your Web3 wallet to proceed</p>
                <p class="trans-tip color-purple cur-pointer flex col-center" @click="toExplorer" v-else>
                    View on Explorer
                    <i class="path-icon"></i>
                </p>
            </div>
            <i class="dot radius-50"></i>
        </div>
    </div>
</template>
 
<script setup>
import { onMounted, onUnmounted, computed } from "vue"
import { useStore } from 'vuex'

const props = defineProps({
    waitNotification: {
        type: Object,
        default: null
    },
})

const { VITE_APP_IMG_PATH } = import.meta.env

const store = useStore()

const iconImg = computed(() => {
    return `${VITE_APP_IMG_PATH}animation/loading_ani.png`
})

onMounted(() => {
    window.addEventListener('beforeunload', refreshPage)
})

onUnmounted(() => {
    window.removeEventListener('beforeunload', refreshPage)
})

const refreshPage = () => {
    store.commit('global/clearWaitNotification')
}

const toExplorer = () => {
    const url = props.waitNotification.explorerUrl

    window.open(url, '_blank')
}

</script>
 
<style lang='less' scoped>
.notification {
    position: fixed;
    right: 24px;
    top: 100px;
    width: 335px;
    padding: 8px 12px;
    box-shadow: 0px 2px 0px 0px #000;
    border: 1px solid #000;
    background: #F5F4FF;
    z-index: 9999;

    .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .sub-title {
        font-size: 15px;
        color: #7B7B7B;
        line-height: 24px;
    }

    .tip {
        font-size: 16px;
        color: #000;
        line-height: 1.5;

        .trans-tip {
            font-size: 15px;

            .path-icon {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url('@/assets/image/wallet/path_icon.png') no-repeat;
                background-size: 100% 100%;
                margin-left: 3px;
            }
        }
    }

    .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #FFDD5B;
        border: 1px solid #000000;
        position: absolute;
        right: 10px;
        top: 8px;
    }
}

@media screen and (max-width: 521px) {
    .notification {
        right: auto;
        left: 50%;
        top: 20px;
        margin-left: -167px;
    }
}
</style>