<template>
  <RouterView />
</template>

<script setup>
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/vue'
import * as Chains from 'viem/chains'

const projectId = 'ca1704ae707cc417fb3cc1f8593a1f39'
const ethersConfig = defaultConfig({
  metadata: {
    name: 'Sparkle',
    description: 'Sparkle',
    url: 'https://sparkle.fun',
    // icons: ['https://lab.web3modal.com/logo.png'],
  },
})
const chains = [
  Chains.bsc,
  Chains.mainnet,
  Chains.polygon,
  Chains.avalanche,
  Chains.opBNB,
  Chains.polygonMumbai,
  Chains.sepolia,
  Chains.linea,
  Chains.opBNBTestnet,
  Chains.bscTestnet,
]
for (let index = 0; index < chains.length; index++) {
  const element = chains[index];
  chains[index] = {
    chainId: element.id,
    name: element.name,
    currency: element.nativeCurrency.symbol,
    explorerUrl: element.blockExplorers.default.url,
    rpcUrl: element.rpcUrls.default.http[0],
    // rpcUrl: 'https://eth-mainnet.g.alchemy.com/v2/1fUm8BdQlcb2d-kz8BeUVujyb21xTAuf' // test rpc
  };
}
const modal = createWeb3Modal({
  projectId,
  ethersConfig,
  chains,
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa', // coinbase
    '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709' // okx
  ],
  termsConditionsUrl: 'https://www.sparkle.fun/terms/terms-of-use.html',
  privacyPolicyUrl: 'https://www.sparkle.fun/terms/privacy-policy.html',
  themeMode: 'light',
})
// console.log('%c [ modal ]-40', 'font-size:13px; background:pink; color:#bf2c9f;', modal)
</script>
