import { BigNumber, utils } from "ethers";
const { VITE_APP_uniPass_env } = import.meta.env;

export const getAssetsImg = (src) => {
  if (
    src.indexOf("http://") > -1 ||
    src.indexOf("https://") > -1 ||
    src.indexOf("data:image/") == 0
  ) {
    return src;
  }

  return new URL(`../../assets/image/${src}`, import.meta.url).href;
};

// 防抖
// 在防抖函数中第三个参数immediate，可以实现这种效果
export const debounce = (func, delay, immediate) => {
  let timer;
  return function () {
    if (timer) clearTimeout(timer);
    if (immediate) {
      // 复杂的防抖函数
      // 判断定时器是否为空，如果为空，则会直接执行回调函数
      let firstRun = !timer;
      // 不管定时器是否为空，都会重新开启一个新的定时器,不断输入，不断开启新的定时器，当不在输入的delay后，再次输入就会立即执行回调函数
      timer = setTimeout(() => {
        timer = null;
      }, delay);
      if (firstRun) {
        func.apply(this, arguments);
      }
      // 简单的防抖函数
    } else {
      timer = setTimeout(() => {
        func.apply(this, arguments);
      }, delay);
    }
  };
};

// 节流
export const throttle = (fn, delay = 500) => {
  let _throttleRunning = false;

  if (_throttleRunning) {
    return;
  }
  _throttleRunning = true;
  fn();

  setTimeout(() => {
    _throttleRunning = false;
  }, delay);
};

export const isMobile = () => {
  let tmpU = window.navigator.userAgent.toLowerCase();

  return /AppleWebKit.*Mobile.*/i.test(tmpU);
};

export const addZero = (num) => {
  return num > 9 ? num : `0${num}`;
};

// 获取 url 参数
export const getQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  var context = "";

  if (r != null) context = r[2];
  reg = null;
  r = null;

  return context == null || context == "" || context == "undefined"
    ? ""
    : context;
};

// 钱包地址脱敏
export const truncateAddress = (address) => {
  if (!address) return "";

  const match = address.match(
    /^(0x[a-zA-Z0-9]{3})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
  );

  if (!match) return address;

  return `${match[1]}…${match[2]}`;
};

// 获取url #后面的参数
export const getQueryStringByStr = (name, str) => {
  let query_string = str; // window.location.hash
  if (!query_string) return null; // 如果无参，返回null

  let reg = /[?&]?([^=]+)=([^&]*)/g;
  let tokens;

  while ((tokens = reg.exec(query_string))) {
    if (decodeURIComponent(tokens[1]) === name) {
      return decodeURIComponent(tokens[2]);
    }
  }

  return null;
};

// 获取今天日期或者倒计时时间
export const getToday = (date) => {
  const now = new Date();
  const year = now.getFullYear();
  const month = addZero(now.getMonth() + 1);
  const day = addZero(now.getDate());

  return [year, month, day].join("-");
};

// 日期补 0
export const dateAddZero = (date) => {
  const arr = date.split("-");
  const month = addZero(arr[1]);
  const day = addZero(arr[2]);

  return `${arr[0]}-${month}-${day}`;
};

// 获取最近时间  getDay(0) 当天日期   getDay(-3) 获取最近3天日期
export const getDay = (day) => {
  let today = new Date();
  let targetDayMilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;

  today.setTime(targetDayMilliseconds);

  let tYear = today.getFullYear();
  let tMonth = today.getMonth();
  let tDate = today.getDate();

  tMonth = addZero(tMonth + 1);
  tDate = addZero(tDate);

  return `${tYear}-${tMonth}-${tDate}`;
};

// js时间戳转换几分前几小时前几天前几周前
export const calculateTimeAgo = (dateTimeStamp) => {
  const minute = 60 * 1000; // 把分，时，天，周，半个月，一个月用毫秒表示

  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const now = new Date().getTime(); // 获取当前时间毫秒

  const diffValue = now - dateTimeStamp * 1000; // 时间差

  let result = "";

  if (diffValue < 0) {
    return;
  }

  const minC = diffValue / minute; // 计算时间差的分，时，天，周，月
  const hourC = diffValue / hour;
  const dayC = diffValue / day;
  const weekC = diffValue / week;

  if (weekC >= 1 && weekC <= 4) {
    result = ` ${parseInt(weekC, 10)} weeks`;
  } else if (dayC >= 1 && dayC <= 6) {
    result = ` ${parseInt(dayC, 10)} days`;
  } else if (hourC >= 1 && hourC <= 23) {
    result = ` ${parseInt(hourC, 10)} hours`;
  } else if (minC >= 1 && minC <= 59) {
    result = ` ${parseInt(minC, 10)} minutes`;
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "just now";
  } else {
    const dateTime = new Date();

    dateTime.setTime(dateTimeStamp * 1000);

    const nYear = dateTime.getFullYear();
    const nMonth = addZero(dateTime.getMonth() + 1);
    const nDate = addZero(dateTime.getDate());

    result = `${nYear}-${nMonth}-${nDate}`;
  }

  return result;
};

// 时间戳：1637244864707
/* 时间戳转换为时间 */
export const timestampToTime = (timestamp, interval = "-") => {
  let date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

  const Y = date.getFullYear();
  const M = date.getMonth() + 1;
  const D = date.getDate();

  return `${Y}${interval}${M}${interval}${D}`;
};

// 获取倒计时日期
export const getCountDown = (timestamp1, timestamp2) => {
  var diff = Math.abs(timestamp2 - timestamp1); // 获取时间戳的差值（单位为毫秒）
  console.log("getCountDown", diff);
  var days = Math.floor(diff / (1000 * 60 * 60 * 24)); // 将差值转换成天数
  var hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // 将余下部分转换成小时数
  var minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); // 将余下部分转换成分钟数

  return [days, hours, minutes];
};

// js对象数组去重，并取值最后一个相同的对象
export const uniqueHard = (arr, name) => {
  let hash = {};

  return arr.reduce(function (acc, cru) {
    if (!hash[cru[name]]) {
      hash[cru[name]] = { index: acc.length };
      acc.push(cru);
    } else {
      acc.splice(hash[cru[name]]["index"], 1, cru);
    }

    return acc;
  }, []);
};

// 截取字符串中某段位置替换成...
export const replaceWithEllipsis = (text, start, end) => {
  if (!text || !start || !end) {
    return "";
  }

  if (start >= end) {
    return text;
  }

  const beforeText = text.slice(0, start);
  const afterText = text.slice(end);
  const replacedText = `${beforeText}......${afterText}`;

  return replacedText;
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);

  // 定义月份的英文缩写数组
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // 获取年、月、日
  const year = date.getFullYear();
  const monthIndex = date.getMonth(); // 月份从0开始，需要减1
  const day = date.getDate();

  // 根据月份索引获取对应的英文缩写
  const monthName = monthNames[monthIndex];
  const formattedDate = monthName + " " + day + ", " + year;

  return formattedDate;
};

export const formatDetailDate = (timestamp) => {
  const date = new Date(timestamp * 1000);

  const year = date.getFullYear();
  const month = addZero(date.getMonth() + 1);
  const day = addZero(date.getDate());
  const hour = addZero(date.getHours());
  const minute = addZero(date.getMinutes());
  const second = addZero(date.getSeconds());

  return `${month}/${day}/${year}, ${hour}:${minute}:${second}`;
};

// 十六进制转化
export const hexadecimalTimeConversion = (dateString) => {
  const timestamp = parseInt(dateString, 16); // 将十六进制时间戳转换为十进制
  const dateObject = new Date(timestamp * 1000); // 将时间戳转换为毫秒，然后创建日期对象
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = dateObject.toLocaleDateString("en-US", options);

  return formattedDate;
};

// 转化钱包显示金额
export const getPriceConversion = (num) => {
  if (num < 1) {
    return parseFloat(Number(num).toFixed(6));
  }
  if (num >= 1 && num < 1000) {
    return parseFloat(Number(num).toFixed(3));
  }
  if (num >= 1000) {
    return parseFloat(Number(num).toFixed(0)).toLocaleString();
  }
};

export const conversionBnbAmount = (num, decimals = 18) => {
  const _num = num || "0";

  return getPriceConversion(utils.formatUnits(BigNumber.from(_num), decimals));
};

// 验证钱包地址的格式
export const validateWalletAddress = (address) => {
  // Ethereum地址的格式由0x开头，后面跟着40个十六进制字符
  var pattern = /^0x[a-fA-F0-9]{40}$/;

  return pattern.test(address);
};

// 验证钱包输入数量
export const validatePositiveNumber = (val) => {
  const number = parseFloat(val);

  return !isNaN(number) && number > 0;
};

// 判断环境
export const isDev = () => {
  return VITE_APP_uniPass_env == "dev" || VITE_APP_uniPass_env == "testnet";
};

/**
 * Converts a Uint8Array to a hexadecimal string.
 *
 * @param uint8arr - The Uint8Array to convert.
 * @returns The hexadecimal string representation of the Uint8Array.
 */
export function uint8ToHex(uint8arr) {
  let hexStr = "";

  for (let i = 0; i < uint8arr.length; i++) {
    let hex = uint8arr[i].toString(16);

    hex = hex.length === 1 ? "0" + hex : hex;
    hexStr += hex;
  }
  return "0x" + hexStr;
}

/**
 * Converts a string to its hexadecimal representation.
 *
 * @param str - The string to convert.
 * @returns The hexadecimal representation of the string.
 */
export const stringToHex = (str) => {
  let encoder = new TextEncoder();
  let view = encoder.encode(str);

  return uint8ToHex(view);
};

// 判断类型集合
export const checkStr = (str, type) => {
  switch (type) {
    case "mobileOversea": // 海外手机号码
      return /^\d{5,18}$/.test(str);
    case "mobile": //手机号码
      return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(str);
    case "tel": //座机
      return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
    case "card": //身份证
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str);
    case "mobileCode": //6位数字验证码
      return /^[0-9]{6}$/.test(str);
    case "pwd": //密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
      return /^([a-zA-Z0-9_]){6,18}$/.test(str);
    case "payPwd": //支付密码 6位纯数字
      return /^[0-9]{6}$/.test(str);
    case "postal": //邮政编码
      return /[1-9]\d{5}(?!\d)/.test(str);
    case "QQ": //QQ号
      return /^[1-9][0-9]{4,9}$/.test(str);
    case "email": //邮箱
      return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str);
    case "money": //金额(小数点2位)
      return /^\d*(?:\.\d{0,2})?$/.test(str);
    case "URL": //网址
      return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(
        str
      );
    case "IP": //IP
      return /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/.test(
        str
      );
    case "date": //日期时间
      return (
        /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2})(?:\:\d{2}|:(\d{2}):(\d{2}))$/.test(
          str
        ) || /^(\d{4})\-(\d{2})\-(\d{2})$/.test(str)
      );
    case "number": //数字
      return /^[0-9]$/.test(str);
    case "english": //英文
      return /^[a-zA-Z]+$/.test(str);
    case "chinese": //中文
      return /^[\\u4E00-\\u9FA5]+$/.test(str);
    case "lower": //小写
      return /^[a-z]+$/.test(str);
    case "upper": //大写
      return /^[A-Z]+$/.test(str);
    case "HTML": //HTML标记
      return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
    default:
      return true;
  }
};
