import "@/common/utils/polyfills";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import components from "@/components/index";
import directive from "@/common/utils/directive";
import { isMobile, isDev } from "@/common/utils/util";
import { imgsPreloader } from "@/common/lib/imgPreloader";
import imgPreloaderList from "@/common/lib/imgPreloaderList";

import "virtual:svg-icons-register";
import "animate.css";

import "@/assets/font/font.css";
import "./assets/style/main.less";

import VConsole from "vconsole";

const app = createApp(App);

directive(app); // 注册全局指令

app.use(components); // 注册全局组件

app.use(store);

app.use(router);

app.mount("#app");

imgsPreloader(imgPreloaderList);

setTimeout(() => {
  //关闭加载弹框
  document.querySelector("#global-loading").style.display = "none";
}, 4500);

if (isDev() && isMobile()) {
  const vConsole = new VConsole();
}
